import React from "react";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Navigation from "../components/Navigation/Navigation.jsx";
import Projects from "../components/Project/Project.jsx";


function projects(props){
    return (
        <div>
            <Navigation />
            <Projects />
            <Footer />
        </div>
    )
    
};

export default projects;