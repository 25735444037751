import React from "react";
import "./Skill.css";

function Skills() {
  return (
    <div className="skills-section">
      <h2>My Skills</h2>
      <div className="skills-container">
        <div className="skill-item">
          <h3>Web Development</h3>
          <p>Experienced in Java, HTML, CSS, JavaScript (Node.js, Express.js), and in creating web apps using frameworks like React and Spring Boot.</p>
        </div>

        <div className="skill-item">
          <h3>Software Engineering</h3>
          <p>Solid understanding of software development and object-oriented programming using encapsulation, polymorphism, and inheritance. Experienced in Agile and Scrum methodologies.</p>
        </div>

        <div className="skill-item">
          <h3>Database Management</h3>
          <p>Confident in setting up and querying SQL databases using MySQL, and beginner experience in No-SQL databases with MongoDB Atlas.</p>
        </div>

        <div className="skill-item">
          <h3>Problem Solving</h3>
          <p>Evidence of competence and ability to think logically and critically to produce efficient software solutions, as proven in university projects.</p>
        </div>

        <div className="skill-item">
          <h3>Teamwork & Communication</h3>
          <p>Proficient in teamwork and maintaining effective communication to achieve success in collaborative environments.</p>
        </div>
      </div>
    </div>

  );
}

export default Skills;

// import React from 'react';
// import './Skill.css';

// function Skills() {
//   return (
//     <section className="skills-section">
//       <h2>Expertise and Abilities</h2>
//       <div className="skills-container">
        
//         {/* Technical Skills */}
//         <div className="skill-item">
//           <h3>Full-Stack Development</h3>
//           <p>Expertise in end-to-end web application development using MERN stack (MongoDB, Express.js, React.js, Node.js), with a focus on crafting scalable and SEO-friendly web solutions.</p>
//         </div>
//         <div className="skill-item">
//           <h3>Agile Software Development</h3>
//           <p>Proficient in the Agile framework with certification in Scrum methodology, emphasizing rapid iteration, continuous feedback, and high-quality output.</p>
//         </div>
//         <div className="skill-item">
//           <h3>Data Engineering</h3>
//           <p>Experience in designing robust data pipelines, implementing ETL processes, and performing data analysis with Python and SQL for actionable insights.</p>
//         </div>
        
//         {/* Soft Skills */}
//         <div className="skill-item">
//           <h3>Strategic Thinking</h3>
//           <p>Adept at developing long-term strategies based on market trends and technological advancements, ensuring sustainable growth and innovation.</p>
//         </div>
//         <div className="skill-item">
//           <h3>Critical Analysis</h3>
//           <p>Proven ability to critically evaluate code, design, and systems for optimization and enhancement, leading to more efficient development processes.</p>
//         </div>

//         {/* New Technical Skills */}
//         <div className="skill-item">
//           <h3>Cloud Computing</h3>
//           <p>Knowledgeable in deploying scalable applications on AWS and Azure, utilizing cloud services for storage, computation, and security.</p>
//         </div>
//         <div className="skill-item">
//           <h3>DevOps Practices</h3>
//           <p>Understanding of DevOps principles, with experience in CI/CD pipelines, containerization with Docker, and orchestration with Kubernetes.</p>
//         </div>
//         <div className="skill-item">
//           <h3>UI/UX Design</h3>
//           <p>Familiar with the principles of design thinking and user experience, capable of creating intuitive interfaces with user-centric workflows.</p>
//         </div>

//         {/* Personal Attributes */}
//         <div className="skill-item">
//           <h3>Adaptability & Resilience</h3>
//           <p>Comfortable with fast-paced environments and shifting priorities, maintaining a positive attitude and strong performance under pressure.</p>
//         </div>
//         <div className="skill-item">
//           <h3>Innovative Problem-Solving</h3>
//           <p>Exceptional creativity in problem-solving, applying unconventional thinking to overcome complex challenges and drive technological breakthroughs.</p>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Skills;

