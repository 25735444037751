import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import "./Contact.css";

function Contact(props) {
  return (
    <div className="contact-section">
      <h3>Connect with Me:</h3>
      <ul className="social-media">
        <li>
          <a href="https://www.linkedin.com/in/mshaafkashan/" target="_blank" rel="noreferrer">
            <LinkedInIcon style={{ fontSize: "8em" }} className="social-icon linkedin" />
          </a>
        </li>
        <li>
          <a href="https://github.com/Shaafkashan/" target="_blank" rel="noreferrer">
            <GitHubIcon style={{ fontSize: "8em" }} className="social-icon github" />
          </a>
        </li>
        <li>
          <a href="#/" target="_blank" rel="noreferrer">
            <TwitterIcon style={{ fontSize: "8em" }} className="social-icon twitter" />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Contact;
