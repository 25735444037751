const GCSE = [
    {
        id: "1",
        subjectName: "Mathematics",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "8(A*)"
    },

    {
        id: "2",
        subjectName: "Biology",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "9(A**)"
    },

    {
        id: "3",
        subjectName: "Chemistry",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "8(A*)"
    },

    {
        id: "4",
        subjectName: "Physics",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "8(A*)"
    },

    {
        id: "5",
        subjectName: "History",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "8(A*)"
    },

    {
        id: "6",
        subjectName: "Computer Science",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "8(A*)"
    },

    {
        id: "7",
        subjectName: "English Literature",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "5(C)"
    },

    {
        id: "8",
        subjectName: "English Language",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "5(C)"
    },

    {
        id: "9",
        subjectName: "Urdu",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "8(A*)"
    },

    {
        id: "10",
        subjectName: "French",
        startDate: "2017",
        endDate: "2020",
        gradeAcheived: "5(C)"
    },

];

export default GCSE;

