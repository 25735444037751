import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Alevels from "../../content/ALevels.js";
import GCSE from "../../content/GCSE.js";
import CS from "../../content/CS.js";
import EducationCard from "./EduCard.jsx";
import "./Education.css";

function generateEducationCard(education) {
  return (
    <EducationCard
      key={education.id}
      subjectName={education.subjectName}
      startDate={education.startDate}
      endDate={education.endDate}
      gradeAcheived={education.gradeAcheived}
    />
  );
}

function Education() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="education-section">
      <div className="education-block" data-aos="fade-up" data-aos-delay="100" onClick={() => AOS.refresh()}>
        <h1>Computer Science</h1>
        <h2>Brunel University London</h2>
        {CS.map(generateEducationCard)}
      </div>
      

      <div className="education-block">
        <h1>A Levels</h1>
        <h2>Bishopshalt School</h2>
        {Alevels.map(generateEducationCard)}
      </div>

      <div className="education-block">
        <h1>GCSE</h1>
        <h2>Bishopshalt School</h2>
        {GCSE.map(generateEducationCard)}
      </div>
      
    </div>
  );
}

export default Education;
