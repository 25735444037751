import React, { useState } from "react";
import Modal from "./Modal"; // Ensure you have a modal component
import "./ViewMyWork.css";
import Navigation from "../../components/Navigation/Navigation";

const ViewMyWork = () => {
  const [modalContent, setModalContent] = useState(null);

  const projectDetails = {
    "Probot Project": {
      title: "Probot Project - First Year",
      description:
        "An advanced robotics project leveraging Raspberry Pi and Java to perform complex tasks, including automated movements and interactive tasks. Click for more details and see the robot in action!",
      videoUrl: "", // No video for the first project
    },
    "Financial Management System": {
      title: "Financial Management System - Second Year",
      description:
        "This project created a robust financial management platform aimed at enhancing financial literacy among young adults. Explore features like budget tracking, financial forecasting, and educational modules.",
      videoUrl: "https://www.youtube.com/embed/20eJWSW6bg4" // Updated to a correct embed URL
    },
  };

  const openModal = (projectKey) => {
    setModalContent(projectDetails[projectKey]);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <div className="work-page">
      <Navigation />
      <h1>My Work</h1>
      <div className="work-container">
        {Object.keys(projectDetails).map((key) => (
          <div className="project" key={key} onClick={() => openModal(key)}>
            <h2>{projectDetails[key].title}</h2>
            <p>{projectDetails[key].description}</p>
          </div>
        ))}
      </div>
      {modalContent && <Modal content={modalContent} closeModal={closeModal} />}
    </div>
  );
};

export default ViewMyWork;
