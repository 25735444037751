const CS = [
  // {
  //   id: "1",
  //   subjectName: "Second Year Group Project",
  //   startDate: "2023",
  //   endDate: "2024",
  //   gradeAcheived: "A*",
  // },

  // {
  //   id: "2",
  //   subjectName: "Algorithms and their Applications",
  //   startDate: "2023",
  //   endDate: "2024",
  //   gradeAcheived: "A",
  // },

  // {
  //   id: "3",
  //   subjectName: "Software Development and Management",
  //   startDate: "2023",
  //   endDate: "2024",
  //   gradeAcheived: "A+",
  // },

  // {
  //   id: "4",
  //   subjectName: "Network and Operating Systems",
  //   startDate: "2023",
  //   endDate: "2024",
  //   gradeAcheived: "C+",
  // },

  // {
  //   id: "5",
  //   subjectName: "Usibility Engineering",
  //   startDate: "2023",
  //   endDate: "2024",
  //   gradeAcheived: "A",
  // },

  {
    id: "6",
    subjectName: "Second Year Group Project",
    startDate: "2023",
    endDate: "2024",
    gradeAcheived: "A*",
  },

  {
    id: "7",
    subjectName: "Algorithms and their Applications",
    startDate: "2023",
    endDate: "2024",
    gradeAcheived: "A",
  },

  {
    id: "8",
    subjectName: "Software Development and Management",
    startDate: "2023",
    endDate: "2024",
    gradeAcheived: "A+",
  },

  {
    id: "9",
    subjectName: "Network and Operating Systems",
    startDate: "2023",
    endDate: "2024",
    gradeAcheived: "C+",
  },

  {
    id: "10",
    subjectName: "Usibility Engineering",
    startDate: "2023",
    endDate: "2024",
    gradeAcheived: "A",
  },

  {
    id: "11",
    subjectName: "First Year Group Project",
    startDate: "2022",
    endDate: "2023",
    gradeAcheived: "A",
  },

  {
    id: "12",
    subjectName: "Introductory Programming",
    startDate: "2022",
    endDate: "2023",
    gradeAcheived: "A",
  },

  {
    id: "13",
    subjectName: "Logic and Computaion",
    startDate: "2022",
    endDate: "2023",
    gradeAcheived: "B",
  },
];

export default CS;
