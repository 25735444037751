import React from "react";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Navigation from "../components/Navigation/Navigation.jsx";
import Contact from "../components/Contact/Contact.jsx";



function contact(props){
    return (
        <div>
            <Navigation />
            <Contact />
            <Footer />
        </div>
    )
    
};

export default contact;