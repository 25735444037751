import React, { useEffect, useState } from "react";
import './Header.css'; 
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Header(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="header" style={{ backgroundPositionY: `${offsetY * 0.5}px` }}>
      <div className="header-content">
        <h1 data-aos="fade-right" data-aos-delay="200">Muhammad Shaaf Kashan</h1>
        <h2 data-aos="fade-right" data-aos-delay="400">Aspiring Software Engineer & AI Enthusiast</h2>
        <p data-aos="fade-up" data-aos-delay="600">
          Currently in my second year at Brunel University London, I am passionately
          diving into the realms of Computer Science, with a keen focus on Artificial Intelligence.
          My academic journey is complemented by practical projects and collaborative team experiences,
          where I apply my knowledge in software development.
        </p>
        <p data-aos="fade-up" data-aos-delay="800">
          Beyond the classroom, I actively engage in self-learning, constantly updating my skills in
          the latest technologies and software development trends. My goal is to innovate and develop
          solutions that make a significant impact in the tech world.
        </p>
        <Link to="/mywork" className="resume-button" data-aos="zoom-in" data-aos-delay="1000">My Work</Link>
      </div>
    </div>
  );
}

export default Header;
