import React from "react";
import "./Education.css";

function EducationCard(props) {
    return (
        <div className="card">
            <div className="card-header">
                <h2 className="subject-name">{props.subjectName}</h2>
                <h3 className="grade-achieved">Grade: <span className="grade">{props.gradeAcheived}</span></h3>
            </div>
            <div className="card-body">
                <p className="date">{props.startDate} to {props.endDate}</p>
            </div>
        </div>
    );
}

export default EducationCard;
