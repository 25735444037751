import React from 'react';
import './About.css';
import Particles from 'react-tsparticles';

function About() {
  const particlesOptions = {
    fpsLimit: 60,
    particles: {
      number: {
        value: 80,
        density: {
          enable: true,
          value_area: 800
        }
      },
      color: {
        value: "#ffffff"
      },
      shape: {
        type: "circle"
      },
      opacity: {
        value: 0.5,
        anim: {
          enable: true,
          speed: 1,
          opacity_min: 0.1,
          sync: true
        }
      },
      size: {
        value: 3
      },
      links: {
        enable: false
      },
      move: {
        enable: true,
        speed: 1,
        direction: "none",
        random: false,
        straight: false,
        out_mode: "out",
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200
        }
      }
    },
    interactivity: {
      detectsOn: "canvas",
      events: {
        onHover: {
          enable: true,
          mode: "repulse"
        },
        onClick: {
          enable: true,
          mode: "push"
        },
        resize: true
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1
          }
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 0.8,
          speed: 3
        },
        repulse: {
          distance: 200
        },
        push: {
          particles_nb: 4
        },
        remove: {
          particles_nb: 2
        }
      }
    },
    detectRetina: true
  };

  return (
    <div className="about-container">
      <Particles options={particlesOptions} />
      <div className="about-me">
        <h2>About Me</h2>
        <p>Hello! I’m Muhammad Shaaf Kashan, an aspiring Software Engineer deeply invested in the field of Computer Science. I am currently honing my skills at Brunel University London, where I am pursuing my BSc (Hons) in Computer Science from September 2022 through June 2026.</p>
        <p>My academic journey is underpinned by a strong foundation in various programming languages and web development frameworks. I am experienced in Java, HTML, CSS, and JavaScript, including Node.js and Express.js. Additionally, I have developed competence in creating web applications using popular frameworks like React and Spring Boot.</p>
        <p>One of my key projects at Brunel University involved a Java project where I leveraged my programming skills to develop functional software. This project not only allowed me to apply my theoretical knowledge but also helped me understand the practical aspects of software development.</p>
        <p>Beyond my curriculum, I am passionate about Web Development and Interpersonal Communication, as evidenced by my active engagement in courses like the Udemy Web Development Bootcamp and LinkedIn Learning. These pursuits reflect my commitment to continuous learning and adapting to the ever-evolving technological landscape.</p>
        <p>In my quest to become a Software Engineer, I am driven by a desire to innovate and develop solutions that make a significant impact in the tech world. My goal is to merge technical skills with a deep understanding of user needs, aiming to create software solutions that are not only efficient but also intuitive and user-friendly.</p>
      </div>
    </div>
  );
}

export default About;
