import React from 'react';
import './Modal.css'; // Ensure you have styling for the modal

const Modal = ({ content, closeModal }) => {
    return (
        <div className="modal-backdrop" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>{content.title}</h2>
                <p>{content.description}</p>
                {content.videoUrl && (
                    <div className="video-container">
                        <iframe 
                            width="560" 
                            height="315" 
                            src={content.videoUrl} 
                            title="YouTube video player"
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                        </iframe>
                    </div>
                )}
                <button className="close-btn" onClick={closeModal}>Close</button>
            </div>
        </div>
    );
};

export default Modal;
