const Alevels = [
    {
        id: "1",
        subjectName: "Mathematics",
        startDate: "2020",
        endDate: "2022",
        gradeAcheived: "A"
    },

    {
        id: "2",
        subjectName: "Chemistry",
        startDate: "2020",
        endDate: "2022",
        gradeAcheived: "A"
    },
    {
        id: "3",
        subjectName: "Biology",
        startDate: "2020",
        endDate: "2022",
        gradeAcheived: "B"
    }
]

export default Alevels;