import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.css';

function Navigation() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (menuRef.current) {
      if (isMenuOpen) {
        menuRef.current.style.maxHeight = `${menuRef.current.scrollHeight + 50}px`; // Adjusted additional height
      } else {
        menuRef.current.style.maxHeight = '0';
      }
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 769) {
        menuRef.current.style.maxHeight = 'none';
      } else if (!isMenuOpen) {
        menuRef.current.style.maxHeight = '0';
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMenuOpen]);

  return (
    <nav className="custom-navigation">
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul ref={menuRef} className={`custom-nav-menu ${isMenuOpen ? 'show' : ''}`}>
        <li><NavLink to="/" className="custom-nav-link">Home</NavLink></li>
        <li><NavLink to="/aboutme" className="custom-nav-link">About Me</NavLink></li>
        <li><NavLink to="/education" className="custom-nav-link">Education</NavLink></li>
        <li><NavLink to="/skills" className="custom-nav-link">Skills</NavLink></li>
        <li><NavLink to="/projects" className="custom-nav-link">Projects</NavLink></li>
        <li><NavLink to="/contact" className="custom-nav-link">Contact</NavLink></li>
      </ul>
    </nav>
  );
}

export default Navigation;
