import React from "react";
import { Link } from "react-router-dom";
import "./ErrorPage.css";
import Bug from "./Bug.png";

const ErrorPage = () => {
  return (
    <div className="error-container">
      <div className="error-content">
        <div className="error-animation">
          {/* Animated character or graphic goes here */}
          <img src={Bug} alt="Engaging Visual" />{" "}
        </div>
        <h1 className="error-title">Whoops! We've Hit a Black Hole!</h1>
        <p className="error-message">
          The page you are looking for seems to be swallowed by a black hole.
          Let's get you back to safety!
        </p>
        <Link to="/" className="resume-button">
          Return to Home Base
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
