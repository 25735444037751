import React from "react";
import "./Project.css";

function Project() {
  return (
    <div className="projects">
      <h1>Projects</h1>

      {/* Year 2 Group Project */}
      <div className="project-card">
        <h2>Brunel Year 2 Group Project - Financial Management Application</h2>
        <h3>Timeframe: 2023 - Present</h3>
        <p>
          Our team is developing a comprehensive financial management
          application, featuring asset tracking, budgeting analysis, and
          investment strategies.
        </p>
        <h4>My Contributions:</h4>
        <ul>
          <li>
            Developed the note-taking feature for instant capture and secure
            storage of thoughts.
          </li>
          <li>
            Implemented input functionality for various asset types with visual
            representation in a pie chart.
          </li>
          <li>
            Contributed to the financial health bar feature for comparing
            financial status with similar user personas.
          </li>
          <li>
            Enabled detailed historical data analysis for asset performance.
          </li>
          <li>
            Ensured seamless integration of transaction data with the home page
            for real-time visualizations.
          </li>
        </ul>
      </div>

      {/* Year 1 Group Project */}
      <div className="project-card">
        <h2>Brunel Year 1 Group Project - Robotic Task Automation</h2>
        <h3>Timeframe: 2022-2023</h3>
        <p>
          Developed Java software for automating various robot tasks, focusing
          on object-oriented programming and code integration.
        </p>
        <h4>My Contributions:</h4>
        <ul>
          <li>Worked on the Navigate and Retrace functions of the robot.</li>
          <li>
            Implemented file handling in Java using buffered reader and writer.
          </li>
          <li>Led the integration process of different project modules.</li>
          <li>
            Guided the team in utilizing advanced OOP features like inheritance.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Project;
