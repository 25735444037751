import React from "react";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Navigation from "../components/Navigation/Navigation.jsx";
import Education from "../components/Education/Education.jsx";


function education(props){
    return (
        <div>
            <Navigation />
            <Education />
            <Footer />
        </div>
    )
    
};

export default education;