import React, { useEffect } from 'react';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Navigation from "../components/Navigation/Navigation";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="home">
      <Header />
      <Navigation />
      <main className="main-content" data-aos="fade-up">
        <h1>Welcome to My Portfolio</h1>
        <p className="intro-text">
          Dive into my journey as a Software Engineer, explore my educational
          accomplishments, discover the skills I've honed, and connect with me
          to learn more about my passion for technology and innovation.
        </p>
        <div className="call-to-action">
          <Link to="/education" className="cta-link" data-aos="fade-right" data-aos-delay="200">Explore My Education</Link>
          <Link to="/skills" className="cta-link" data-aos="fade-right" data-aos-delay="400">Discover My Skills</Link>
          <Link to="/contact" className="cta-link" data-aos="fade-right" data-aos-delay="600">Let's Connect</Link>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
