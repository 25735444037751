import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/HomePage";
import Contact from "./pages/ContactPage";
import Projects from "./pages/ProjectPage";
import Skills from "./pages/SkillsPage";
import Education from "./pages/EducationPage";
import AboutMe from "./pages/AboutMePage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import ViewMyWork from "./pages/ViewMyWork/ViewMyWork";
import "./App.css";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/education" element={<Education />} />
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/mywork" element={<ViewMyWork />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
