import React from "react";
import "./Footer.css";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";

function Footer(props) {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <footer>
        <div className="footer-content">
          <div className="footer-section">
            <h2>About</h2>
            <p>Aspiring software engineer, enhancing experiences one project at a time.</p>
          </div>
          <div className="footer-section">
            <h2>Follow Me</h2>
            <div className="social-icons">
              <a href="https://www.linkedin.com/in/mshaafkashan/" target="_blank" rel="noreferrer"><FaLinkedinIn /></a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          &copy; {currentYear} Muhammad Shaaf Kashan. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default Footer;
